// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// const images = require.context("../images", true)
// const imagePath = name => images(name, true)

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import "stylesheets/application"

import forms from './forms';
import infoslider from './infoslider';
import nav from './nav';
import popup from './popup';
import sticky_nav from './sticky-nav';

document.addEventListener("turbolinks:load", function() {

    forms();
    infoslider();
    nav();
    popup();
    sticky_nav();

    if (document.querySelector('.variant-current')) {
        let active_tab = document.querySelector('.variant-current');
        let active_tab_id = active_tab.dataset.target;
        let active_tab_node = document.getElementById(active_tab_id);
        const tab_nodes = Array.from(document.querySelectorAll('.variant-item'));

        function set_active_tab() {
            if (this === active_tab) {
                return;
            }
            if (this.dataset.target == 'variant-form') {
                document.querySelector('.variants-button').style.display = 'block';
            } else {
                document.querySelector('.variants-button').style.display = 'none';
            }
            active_tab.classList.remove('variant-current');
            active_tab_node.classList.add('hidden');


            active_tab = this;

            active_tab_id = active_tab.dataset.target;
            active_tab_node = tab_nodes.find((x) => {
                return x.id === active_tab_id;
            });

            active_tab.classList.add('variant-current');
            active_tab_node.classList.remove('hidden');
        }

        document.querySelectorAll('.variants-filter span').forEach((x) => x.addEventListener('click', set_active_tab));
    }

    document.querySelectorAll('.close-popup').forEach((x) => {
        x.addEventListener('click', () => {
            document.body.classList.remove('popup-active')
        });
    });


    function mobile_nav(flag) {
        if (flag) {
            document.body.classList.add('mobile-nav-show');
        } else {
            document.body.classList.remove('mobile-nav-show');
        }
    }

    document.querySelector('.header-sandwich-btn').addEventListener('click', () => {
        mobile_nav(true);
    });


    document.querySelector('.nav-close-btn').addEventListener('click', () => {
        mobile_nav(false);
    });

});