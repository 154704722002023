
const sticky_nav = () => {
    let last_position = 0;
    let ticking = false;

    const sticky_container = document.querySelector('.sticky-nav-container');
    const real_header = document.querySelector('.headerbox');
    const btn = document.querySelector('.header-sticky-sandwich-btn');

    const check_sticky_nav = (new_postition) => {
        if ((new_postition < last_position) && (real_header.getBoundingClientRect().bottom < 0)) {
            sticky_container.classList.add('show-sticky-nav');
        } else {
            sticky_container.classList.remove('show-sticky-nav');
            sticky_container.classList.remove('show-sticky-dropdown');
        }
        last_position = new_postition;
    }


    window.addEventListener('scroll', () => {
        let new_known_scroll_position = window.scrollY;
        if (!ticking) {
            window.requestAnimationFrame(() => {
                check_sticky_nav(new_known_scroll_position);
                ticking = false;
            });
            ticking = true;
        }
    });

    btn.addEventListener('click', () => {
        if (sticky_container.classList.contains('show-sticky-dropdown')) {
            sticky_container.classList.remove('show-sticky-dropdown');
        } else {
            sticky_container.classList.add('show-sticky-dropdown');
        }
    })
}

export default sticky_nav;