
function getCoords(elem) {
    let box = elem.getBoundingClientRect();
    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
    };
}


const navigate = (event) => {
    if (window.DATA && window.DATA.loc && window.DATA.loc.c === 'home' && window.DATA.loc.a === 'index') {
        try {
            const href = event.target.href;
            history.replaceState(null, document.title, href);
            document.body.classList.remove('mobile-nav-show');

            const target = document.querySelector(`*[name="${href.split('#')[1]}"]`);
            if (target) {
                event.preventDefault();
                const position = getCoords(document.querySelector(`*[name="${href.split('#')[1]}"]`));
                window.scrollTo({
                    top: position.top,
                    behavior: 'smooth'
                });
            }
        } catch(e) {
            console.error(e.name);
        }
    }
};

const nav = () => {
    document.querySelectorAll('*[data-event-type="navlink"]').forEach((x) => {
        x.addEventListener('click', navigate);
    });
};

export default nav;
