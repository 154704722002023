import jQuery from 'jquery';
import slick from 'slick-carousel';

const infoslider = ($) => {
    const $head = jQuery('.slick-slider-head');
    const $body = jQuery('.slick-slider-body');
    const $images = jQuery('.slider-images');

    const head_slider = $head.slick({
        arrows: false,
        infinite: true,
        fade: true,
        asNavFor: '.slick-slider-body, .slider-images',
        dots: false,
    });

    const body_slider = $body.slick({
        arrows: true,
        infinite: true,
        fade: true,
        asNavFor: '.slick-slider-head, .slider-images',
        dots: true,
        prevArrow: '.slick-slider-btn-prev',
        nextArrow: '.slick-slider-btn-next',
        appendDots: '.slick-slider-dots',
    });

    const image_slider = $images.slick({
        arrows: false,
        infinite: true,
        fade: true,
        asNavFor: '.slick-slider-head, .slick-slider-body',
        dots: false,
    });


    // $body.on('wheel', function(event) {
    //     event.preventDefault();
    //     if (event.originalEvent.deltaY > 0) {
    //         jQuery(this).slick('slickNext');
    //     } else {
    //         jQuery(this).slick('slickPrev');
    //     }
    // })
}

export default infoslider;
