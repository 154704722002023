const TAXSTAT = 2.2;
let firstname, lastname, email;

// Замыкание
(function() {
    /**
     * Корректировка округления десятичных дробей.
     *
     * @param {String}  type  Тип корректировки.
     * @param {Number}  value Число.
     * @param {Integer} exp   Показатель степени (десятичный логарифм основания корректировки).
     * @returns {Number} Скорректированное значение.
     */
    function decimalAdjust(type, value, exp) {
        // Если степень не определена, либо равна нулю...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Если значение не является числом, либо степень не является целым числом...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Сдвиг разрядов
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Обратный сдвиг
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    // Десятичное округление к ближайшему
    if (!Math.round10) {
        Math.round10 = function(value, exp) {
            return decimalAdjust('round', value, exp);
        };
    }
    // Десятичное округление вниз
    if (!Math.floor10) {
        Math.floor10 = function(value, exp) {
            return decimalAdjust('floor', value, exp);
        };
    }
    // Десятичное округление вверх
    if (!Math.ceil10) {
        Math.ceil10 = function(value, exp) {
            return decimalAdjust('ceil', value, exp);
        };
    }
})();


const calcTax = (value) => {
    return Math.ceil10(value * TAXSTAT / 100, -2);
}

const do_confirm = (form) => {
    let data = new FormData(form);
    let preset_value = parseInt(data.get('value'), 10);
    let user_value = parseInt(data.get('user_value'), 10);
    const value = user_value ? user_value : preset_value;
    const tax = calcTax(value);

    try {
        document.querySelector('#sum_calc').value = value + tax;
        document.querySelector('#summ_all').innerHTML = `₴${value + tax}`;
        document.querySelector('#summ_tax').innerHTML = `₴${tax}`;
        document.body.classList.add('show-confirm');
        form.scrollIntoView(top);
    } catch (e) {
        console.error(e);
    }
}

const add_error = (node) => {
    node.classList.add('input_error');
}

const check_radio_btns = (event) => {
    const user_value = event.target.closest('form').querySelector('input[name="user_value"]');
    if (user_value) {
        user_value.value = '';
        user_value.classList.remove('input_error');
    }
}

const validate_value = (form) => {
    const user_value = /^[0-9]+$/.test(form['user_value'].value.replace(/ +/, 'gi'));
    const preset_value = /^[0-9]+$/.test(form['value'].value);
    const value = user_value || preset_value;

    if (!value) {
        form['user_value'].classList.add('input_error');
    }

    return value;
}

const validate = (form) => {
    let valid = true;
    const accept = form['accept'];
    const anonimous = form['anonimous'];

    valid = accept.checked && valid;
    valid = validate_value(form) && valid;

    if (!anonimous.checked) {
        [firstname, lastname, email].forEach((item) => {
            const re = new RegExp(item.dataset.regexp, 'gi');
            const val = re.test(item.value);

            if (!val) {
                add_error(item);
            }

            valid &&= val;
        });
    }

    return valid;
}

const forms = () => {

    firstname = document.querySelector('input[name="firstname"]');
    lastname = document.querySelector('input[name="lastname"]');
    email = document.querySelector('input[name="email"]');

    let inside_form = document.querySelector('#donations-inside-form');

    if (inside_form) {
        inside_form.addEventListener('submit', (event) => {
            if (!validate(inside_form)) {
                event.preventDefault();
            }
        });
    }

    document.querySelectorAll('input[type="text"], input[type="email"], input[type="number"]').forEach((x) => {
        x.addEventListener('focus', function() { this.classList.remove('input_error')} );
    });

    (function(){
        const anonimous = document.getElementById('anonimous');
        if (!anonimous) {
            return;
        }

        anonimous.addEventListener('click', function() {
            let self = this;
            [firstname, lastname, email].forEach((item) => {
                item.readOnly = self.checked;
                item.classList.remove('input_error');
            });
        });
    })();

    document.querySelectorAll('input[name="value"]').forEach((x) => {
        x.addEventListener('click', check_radio_btns);
    });

    document.querySelectorAll('input[name="user_value"]').forEach((x) => {
        x.addEventListener('input', function() {
            this.closest('form').querySelectorAll('input[name="value"]').forEach((y) => {
                y.checked = false;
            });
        });
    });

    document.querySelectorAll('.inline-form-donate').forEach((x) => {
        x.addEventListener('submit', (event) => {
            let valid = validate_value(event.target);
            if (!valid) {
                event.preventDefault();
            }
        })
    });

    (function(){
        const btn = document.querySelector('#goto_confirm');

        if (!btn && !inside_form) {
            return;
        }

        btn.addEventListener('click', () => {
            if (!validate(inside_form)) {
                return;
            }
            do_confirm(inside_form);
        });
    })();
}

export default forms;
